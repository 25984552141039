import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Inline from "../../../../../components/inline"
import InlineHelp from "../../../../../components/inline-help"
import Input from "../../../../../components/input"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Text from "../../../../../components/text"
import PoemHaelfteDesLebens from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/poem-haelfte-des-lebens"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "haelfte-des-lebens",
          chapterId: "01-zwei-haelften",
          taskId: "strophen-und-verse",
        })
        navigate(
          "/kurse/haelfte-des-lebens/01-zwei-haelften/strophen-und-verse/loesung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Strophen und Verse" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Strophen und Verse
            </Heading>
          </Stack>
          <Paragraph>
            Schon in der Schule hatte Hölderlin nicht nur ein Talent für die
            Sprachen, sondern auch für die Mathematik. Dichten bedeutete für ihn
            auch, seine Wörter so zu wählen, dass sie sich in eine ganz
            bestimmte Form einfügen. Anlass genug, die Zahlen hinter dem Gedicht
            ›Hälfte des Lebens‹ einmal genauer anzusehen...
          </Paragraph>

          <TaskSidebar
            main={
              <PaperStack>
                <PoemHaelfteDesLebens />
              </PaperStack>
            }
            sidebar={
              <Stack space={6}>
                <Note variant="task">
                  Zähle die Strophen und Verse des Gedichts und trage sie unten
                  ein.
                </Note>
                <InlineHelp title="Was sind nochmal Strophen & Verse?">
                  Als Verse bezeichnet man die Zeilen in einem Gedicht. Zusammen
                  ergeben die Verse eine Strophe. Ein Gedicht kann aus einer
                  oder auch mehreren Strophen bestehen.
                </InlineHelp>
                <Stack space={3}>
                  <Inline alignY="center">
                    <Text>Das Gedicht hat</Text>
                    <Box sx={{ width: 16 }}>
                      <Input id="strophen" name="strophen" placeholder="-" />
                    </Box>
                    <Text>Strophen.</Text>
                  </Inline>
                  <Inline alignY="center">
                    <Text>Die erste Strophe hat</Text>
                    <Box sx={{ width: 16 }}>
                      <Input id="verse1" name="verse1" placeholder="-" />
                    </Box>
                    <Text>Verse.</Text>
                  </Inline>
                  <Inline alignY="center">
                    <Text>Die zweite Strophe hat</Text>
                    <Box sx={{ width: 16 }}>
                      <Input id="verse2" name="verse2" placeholder="-" />
                    </Box>
                    <Text>Verse.</Text>
                  </Inline>
                </Stack>
              </Stack>
            }
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
